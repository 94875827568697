import { Card } from 'react-bootstrap';
import useCategoriesContext from 'src/hooks/contexts/useCategoriesContext';
import msgs from 'src/util/msgs';
import CategoryItem from './CategoryItem';

export default function SideBar() {
   const [categories] = useCategoriesContext();

   return (
      <Card>
         <Card.Header className="bg-primary">
            <strong className="text-white text-uppercase">{msgs.productCategories}</strong>
         </Card.Header>

         <Card.Body className="py-3">
            {categories.map(x => (
               <CategoryItem key={x.id} item={x} />
            ))}
         </Card.Body>
      </Card>
   );
}
