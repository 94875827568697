import { HTMLAttributes } from 'react';
import { Alert } from 'react-bootstrap';
import FormikForm from 'src/types/formikForm';
import msgs from 'src/util/msgs';

interface Props extends HTMLAttributes<HTMLElement> {
   form: FormikForm<any>;
}

export default function ValidationSummary({ form, ...props }: Props) {
   const order = Object.keys(form.values),
      fields = Object.keys(form.errors)
         .filter(x => form.touched[x])
         .sort((a, b) => order.indexOf(a) - order.indexOf(b));

   return (
      fields.length > 0 && (
         <div {...props}>
            {fields.map((field, index) => (
               <Alert key={index} variant="danger" className="mt-1 mb-0 px-2 py-1">
                  {msgs[field]}: {form.errors[field]}
               </Alert>
            ))}
         </div>
      )
   );
}
