import { useEffect, useState } from 'react';
import { Alert, Card, FormGroup } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import Icon, { icons } from 'src/components/ui/Icon';
import Modal from 'src/components/ui/Modal';
import Setup from 'src/models/setup';
import routes from 'src/routing/routes';
import setupApi from 'src/services/setupApi';
import { setupPhotoPath } from 'src/util/constants';
import msgs from 'src/util/msgs';
import ui from 'src/util/ui';
import { formatString } from 'src/util/util';
import SetupForm from './SetupForm';
import SetupList from './SetupList';

export default function Setups() {
   const { id } = useParams<any>(),
      history = useHistory(),
      [setups, setSetups] = useState<Setup[]>([]),
      [setup, setSetup] = useState<Setup>(),
      [isCreating, setIsCreating] = useState(false);

   async function getSetups() {
      ui.loading();

      const data = await setupApi.get(true);
      setSetups(data);

      ui.hideLoading();
   }

   useEffect(() => {
      getSetups();
   }, []);

   function goToList() {
      history.push(routes.setups);
   }

   async function getSetup() {
      ui.loading();

      try {
         const data = await setupApi.find(id);
         data.photo = formatString(setupPhotoPath, data.id);

         setSetup(data);
      } catch {
         goToList();
      }

      ui.hideLoading();
   }

   useEffect(() => {
      if (id) {
         getSetup();
      } else {
         setSetup(null);
      }
   }, [id]);

   async function remove(setup: Setup) {
      if (await ui.confirm()) {
         ui.loading();

         await setupApi.remove(setup.id);
         getSetups();

         ui.success(msgs.setupDeleted);
         ui.hideLoading();
      }
   }

   return (
      <Card>
         <Card.Body className="pb-1">
            <Card.Title>{msgs.setups}</Card.Title>
            <hr />

            <FormGroup>
               <span className="btn-link cursor-pointer" onClick={() => setIsCreating(true)}>
                  <Icon icon={icons.faPlus} fixedWidth /> {msgs.new}
               </span>
            </FormGroup>

            <SetupList source={setups} onDelete={remove} />

            {!setups.length && <Alert variant="warning">{msgs.noSetups}</Alert>}

            <Modal title={msgs.newSetup} show={isCreating} onHide={() => setIsCreating(false)}>
               <SetupForm
                  onSave={() => {
                     getSetups();
                     setIsCreating(false);
                  }}
               />
            </Modal>

            <Modal title={msgs.editSetup} show={!!setup} onHide={() => goToList()}>
               {setup && (
                  <SetupForm
                     source={setup}
                     onSave={() => {
                        getSetups();
                        goToList();
                     }}
                  />
               )}
            </Modal>
         </Card.Body>
      </Card>
   );
}
