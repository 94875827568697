import { useEffect, useState } from 'react';
import { Alert, Carousel, Image } from 'react-bootstrap';
import useSessionContext from 'src/hooks/contexts/useSessionContext';
import Setup from 'src/models/setup';
import setupApi from 'src/services/setupApi';
import { setupPhotoPath } from 'src/util/constants';
import { formatString } from 'src/util/util';
import ContactInfo from '../shared/contact-info';

export default function Home() {
   const isLoggedIn = useSessionContext(),
      [carouselIndex, setCarouselIndex] = useState(0),
      [setups, setSetups] = useState<Setup[]>([]);

   useEffect(() => {
      (async () => {
         const data = await setupApi.get(isLoggedIn);
         setSetups(data);
      })();
   }, []);

   return (
      <>
         {setups.length > 0 && (
            <Carousel
               className="mb-3"
               fade
               activeIndex={carouselIndex}
               onSelect={selectedIndex => setCarouselIndex(selectedIndex)}
            >
               {setups.map(x => (
                  <Carousel.Item key={x.id}>
                     <Image thumbnail className="w-100" src={formatString(setupPhotoPath, x.id)} alt={x.title} />

                     <Carousel.Caption>
                        <h4>{x.title}</h4>
                     </Carousel.Caption>
                  </Carousel.Item>
               ))}
            </Carousel>
         )}

         <Alert variant="primary">
            <p>
               <strong className="text-uppercase">¡Arma tu PC con nosotros!</strong>
            </p>

            <p>Coméntanos cuál es tu presupuesto y el uso principal que le darás a tu equipo.</p>

            <p>Nosotros generaremos la mejor cotización a tu medida.</p>

            <ContactInfo />
         </Alert>
      </>
   );
}
