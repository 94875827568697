import axios, { AxiosError } from 'axios';
import globals from 'src/util/globals';
import msgs from 'src/util/msgs';
import ui from 'src/util/ui';

export default function errorInterceptor(error: AxiosError<any>) {
   if (axios.isCancel(error)) {
      ui.hideLoading();
   } else {
      if (error.response) {
         const data = error.response.data,
            message = data.error_description || data.error || data;

         if (error.response.status === 401) {
            // Session has expired
            if (!globals.sessionExpiredFrom || globals.sessionExpiredFrom !== globals.currentComponent) {
               globals.sessionExpiredFrom = globals.currentComponent;
            }
         }

         ui.error(message.toString());
      } else {
         ui.error(msgs.systemDown);
      }

      ui.hideLoading();
   }

   return Promise.reject(error);
}
