import Product from 'src/models/product';
import api from './base/api';

const resource = 'products';

class ProductApi {
   get(categoryId: number, isLoggedIn: boolean): Promise<Product[]> {
      return api.get(`categories/${categoryId}/${resource}${isLoggedIn ? '/uncached' : ''}`);
   }

   search(criteria: string): Promise<Product[]> {
      return api.get(`${resource}/search/?criteria=${criteria}`);
   }

   find(id: number, isLoggedIn: boolean): Promise<Product> {
      return api.get(`${resource}/${id}${isLoggedIn ? '/uncached' : ''}`);
   }

   create(data: Product): Promise<number> {
      return api.post(resource, data);
   }

   update(id: number, data: Product): Promise<any> {
      return api.put(`${resource}/${id}`, data);
   }

   remove(id: number): Promise<any> {
      return api.delete(`${resource}/${id}`);
   }

   show(id: number): Promise<any> {
      return api.post(`${resource}/${id}/show`);
   }

   hide(id: number): Promise<any> {
      return api.post(`${resource}/${id}/hide`);
   }

   duplicate(id: number): Promise<number> {
      return api.post(`${resource}/${id}/duplicate`);
   }
}

export default new ProductApi();
