import { Col, Row } from 'react-bootstrap';
import Product from 'src/models/product';
import ProductGridItem from './ProductGridItem';

interface Props {
   products: Product[];
   onRemove: (product: Product) => void;
   showEdit?: boolean;
   showToggle?: boolean;
}

export default function ProductGrid({ products, onRemove, showEdit, showToggle }: Props) {
   return (
      products.length > 0 && (
         <Row>
            {products.map(x => (
               <Col sm={6} md={4} lg={3} className="mb-3" key={x.id}>
                  <ProductGridItem product={x} onRemove={() => onRemove(x)} {...{ showEdit, showToggle }} />
               </Col>
            ))}
         </Row>
      )
   );
}
