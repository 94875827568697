import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Product from 'src/models/product';
import productApi from 'src/services/productApi';
import events from 'src/util/events';
import ui from 'src/util/ui';
import { isValidSearchCriteria } from 'src/util/validations';

export default function useSearch(setProducts: (value: Product[]) => void) {
   const { criteria } = useParams<{ criteria: string }>();

   useEffect(() => {
      if (isValidSearchCriteria(criteria)) {
         (async () => {
            ui.loading();

            events.search.emit(criteria);

            const data = await productApi.search(criteria);
            setProducts(data);

            ui.hideLoading();
         })();
      }
   }, [criteria]);

   return criteria;
}
