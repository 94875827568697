import { useState } from 'react';
import events from 'src/util/events';
import useOnEvent from './useOnEvent';

export default function useLoading(initialState = false) {
   const [isLoading, setIsLoading] = useState(initialState);

   useOnEvent(events.loading, setIsLoading);

   return isLoading;
}
