import { Image } from 'react-bootstrap';
import { imagePlaceholderPath } from 'src/util/constants';
import msgs from 'src/util/msgs';

interface Props {
   src: string;
   imagePlaceholder?: string;
   onChange: (data: string) => void;
   onImageError?: (e: React.SyntheticEvent<HTMLImageElement, Event>) => void;
}

export default function PhotoPicker({ src, imagePlaceholder, onChange, onImageError }: Props) {
   function onFileChange(e: React.ChangeEvent<HTMLInputElement>) {
      if (e.target.files.length) {
         const reader = new FileReader();

         reader.onloadend = ev => {
            onChange(ev.target.result as string);
         };

         reader.readAsDataURL(e.target.files[0]);
      }
   }

   return (
      <label className="mb-0">
         <input type="file" className="d-none" accept=".jpg, .jpeg, .png" onChange={onFileChange} value="" />

         <Image
            thumbnail
            className="cursor-pointer"
            src={src || imagePlaceholder || imagePlaceholderPath}
            onError={onImageError}
            alt={msgs.photo}
            style={{ height: 182 }}
         />
      </label>
   );
}
