import { useEffect, useRef } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import ui from 'src/util/ui';

export default function AppLoader({ show }: { show: boolean }) {
   const $loading = useRef();

   useEffect(() => (show ? ui.fadeIn($loading.current) : ui.fadeOut($loading.current)), [show]);

   return (
      <Container ref={$loading} fluid className="loading-layer">
         <Row className="height-100 align-items-center">
            <Col xs className="text-center">
               <Spinner animation="grow" variant="primary" size="sm" />{' '}
               <Spinner animation="grow" variant="primary" size="sm" />{' '}
               <Spinner animation="grow" variant="primary" size="sm" />
            </Col>
         </Row>
      </Container>
   );
}
