import { CancelTokenSource } from 'axios';

class Globals {
   appName = 'Gamer Store';
   currentComponent: (props: { [x: string]: any }) => JSX.Element;
   pendingRequestTokens: CancelTokenSource[] = [];
   sessionExpiredFrom: (props: { [x: string]: any }) => JSX.Element;
}

const globals = new Globals();

export default globals;
