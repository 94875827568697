import { HTMLAttributes, useState } from 'react';
import { Button, Form, FormControl, InputGroup } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import useOnSearch from 'src/hooks/events/useOnSearch';
import routes, { formatRoute } from 'src/routing/routes';
import msgs from 'src/util/msgs';
import { isValidSearchCriteria } from 'src/util/validations';
import Icon, { icons } from '../../ui/Icon';

export default function SearchBar({ ...props }: HTMLAttributes<any>) {
   const [criteria, setCriteria] = useState(''),
      history = useHistory();

   useOnSearch(setCriteria);

   function onSearch(e: React.FormEvent<HTMLFormElement>) {
      e.preventDefault();

      if (isValidSearchCriteria(criteria)) {
         const route = formatRoute(routes.search, { criteria });
         history.push(route);
      }
   }

   return (
      <Form inline onSubmit={onSearch} {...props}>
         <InputGroup size="sm" className="mt-2 mt-md-0 w-100">
            <FormControl
               type="text"
               value={criteria}
               onChange={e => setCriteria(e.target.value)}
               onBlur={() => setCriteria(criteria.trim())}
               placeholder={msgs.search}
            />

            <InputGroup.Append>
               <Button type="submit">
                  <Icon icon={icons.faSearch} />
               </Button>
            </InputGroup.Append>
         </InputGroup>
      </Form>
   );
}
