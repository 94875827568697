import { useEffect } from 'react';
import useCancelPendingRequests from 'src/hooks/useCancelPendingRequests';
import globals from 'src/util/globals';
import ui from 'src/util/ui';

interface Props {
   title: string;
   component: (props: { [x: string]: any }) => JSX.Element;
}

export default function Page({ title, component: Component, ...props }: Props) {
   useEffect(() => {
      document.title = `${title || Component.name} | ${globals.appName}`;
   }, [title]);

   useEffect(() => {
      globals.currentComponent = Component;
   }, []);

   useEffect(() => {
      ui.scrollTop();
   }, []);

   useCancelPendingRequests();

   return <Component {...props} />;
}
