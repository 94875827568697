import axios from 'axios';
import environment from 'src/util/environment';
import session from 'src/util/session';
import errorInterceptor from './errorInterceptor';
import requestInterceptor from './requestInterceptor';
import responseInterceptor from './responseInterceptor';

const api = axios.create({
   baseURL: environment.apiUrl,
});

api.interceptors.request.use(requestInterceptor);
api.interceptors.response.use(responseInterceptor, errorInterceptor);

export function getAuthParam() {
   return `token=${session.getToken()}`;
}

export default api;
