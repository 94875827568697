import Product from 'src/models/product';
import routes, { formatRoute } from 'src/routing/routes';
import productApi from 'src/services/productApi';
import { defaultProductPhotoPath, productPhotoPath } from './constants';
import msgs from './msgs';
import ui from './ui';
import { formatString } from './util';

export function getProductPhotoPath(id: number) {
   return formatString(productPhotoPath, id);
}

export function onImageError(e: React.SyntheticEvent<HTMLImageElement, Event>) {
   e.currentTarget.src = defaultProductPhotoPath;
}

export function getDetailRoute(product: Product) {
   return formatRoute(routes.productDetail, {
      id: product.id,
      name: product.name.replaceAll(' ', '-'),
   })
}

export function getEditRoute(id: number) {
   return formatRoute(routes.editProduct, { id: id });
}

export async function deleteProduct(id: number) {
   const confirm = await ui.confirm();

   if (confirm) {
      ui.loading();

      await productApi.remove(id);

      ui.success(msgs.productDeleted);
      ui.hideLoading();
   }

   return confirm;
}

export async function toggleVisiblity(product: Product) {
   ui.loading();

   if (product.isActive) {
      await productApi.hide(product.id);
   } else {
      await productApi.show(product.id);
   }

   product.isActive = !product.isActive;

   ui.success(msgs.productSaved);
   ui.hideLoading();
}
