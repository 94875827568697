import { Col, Container, Row } from 'react-bootstrap';
import CartContext from 'src/contexts/cartContext';
import CategoriesContext from 'src/contexts/categoriesContext';
import useSessionContext from 'src/hooks/contexts/useSessionContext';
import useOnLogin from 'src/hooks/events/useOnLogin';
import Category from 'src/models/category';
import PageRoutes from 'src/routing/PageRoutes';
import globals from 'src/util/globals';
import useCart from '../../hooks/cart/useCart';
import AdminPanel from './AdminPanel';
import Partners from './Partners';
import SideBar from './SideBar';
import TopBar from './TopBar';

interface Props {
   categories: Category[];
   allCategories: Category[];
}

export default function Layout({ categories, allCategories }: Props) {
   const isLoggedIn = useSessionContext(),
      cart = useCart();

   useOnLogin(() => {
      globals.sessionExpiredFrom = undefined;
   });

   return (
      <>
         <CartContext.Provider value={cart}>
            <TopBar />

            <Container fluid="xl" className="my-3">
               <Row>
                  <CategoriesContext.Provider value={[categories, allCategories]}>
                     <Col sm="auto">
                        <SideBar />
                        {isLoggedIn && <AdminPanel />}
                     </Col>

                     <Col>
                        <PageRoutes />
                     </Col>
                  </CategoriesContext.Provider>
               </Row>
            </Container>
         </CartContext.Provider>

         <Partners />
      </>
   );
}
