import axios, { AxiosRequestConfig } from 'axios';
import globals from 'src/util/globals';
import session from 'src/util/session';

export default function requestInterceptor(config: AxiosRequestConfig) {
   try {
      config.headers = {
         Accept: 'application/json; odata=verbose',
         'Content-Type': 'application/json; charset=utf-8',
         Authorization: getAuthHeader(),
         ...config.headers,
      };

      if (config.cancelToken !== null) {
         const cancelTokenSource = axios.CancelToken.source();
         config.cancelToken = cancelTokenSource.token;

         if (globals.currentComponent) {
            globals.pendingRequestTokens.push(cancelTokenSource);
         }
      }

      return config;
   } catch (error) {
      return Promise.reject(error);
   }
}

function getAuthHeader() {
   const token = session.getToken();

   return token || '';
}
