import { useEffect } from 'react';
import { AppEvent } from 'src/util/events';

export default function useOnEvent(event: AppEvent<any>, callback: Function, deps?: React.DependencyList) {
   useEffect(() => {
      event.subscribe(callback);

      return () => event.unsubscribe(callback);
   }, deps || []);
}
