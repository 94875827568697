import { Button, Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Icon, { icons } from 'src/components/ui/Icon';
import Tooltip from 'src/components/ui/Tooltip';
import Setup from 'src/models/setup';
import routes, { formatRoute } from 'src/routing/routes';
import { setupPhotoPath } from 'src/util/constants';
import msgs from 'src/util/msgs';
import { formatString } from 'src/util/util';

interface Props {
   source: Setup[];
   onDelete: (record: Setup) => void;
}

export default function SetupList({ source, onDelete }: Props) {
   return (
      <Row>
         {source.map(x => (
            <Col sm={6} md={4} className="mb-3" key={x.id}>
               <Card className="product-grid-item">
                  <div className="img-container">
                     <Card.Img variant="top" src={formatString(setupPhotoPath, x.id)} />

                     <Card.ImgOverlay className="d-flex h-100">
                        <div className="m-auto text-center">
                           <Tooltip title={msgs.edit}>
                              <Link
                                 to={formatRoute(routes.editSetup, { id: x.id })}
                                 className="btn btn-outline-primary btn-sm m-1"
                              >
                                 <Icon icon={icons.faEdit} />
                              </Link>
                           </Tooltip>

                           <Tooltip title={msgs.delete}>
                              <Button
                                 variant="outline-primary"
                                 size="sm"
                                 className="m-1"
                                 onClick={() => onDelete(x)}
                              >
                                 <Icon icon={icons.faTrash} />
                              </Button>
                           </Tooltip>
                        </div>
                     </Card.ImgOverlay>
                  </div>

                  <Card.Body>
                     <h6 className="mb-0 font-weight-normal">{x.title}</h6>
                  </Card.Body>
               </Card>
            </Col>
         ))}
      </Row>
   );
}
