import { Modal as BsModal, ModalProps } from 'react-bootstrap';

interface Props extends ModalProps {
   title: string;
}

export default function Modal({ title, ...props }: Props) {
   return (
      <BsModal {...props}>
         <BsModal.Header closeButton>
            <BsModal.Title as="h5">{title}</BsModal.Title>
         </BsModal.Header>

         <BsModal.Body>{props.children}</BsModal.Body>
      </BsModal>
   );
}
