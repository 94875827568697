import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import Icon from './Icon';

interface Props {
   icon: IconDefinition;
   message: string;
}

export default function ToastContent({ icon, message }: Props) {
   return (
      <div className="d-flex align-items-baseline">
         <Icon icon={icon} fixedWidth className="my-auto mr-2" />
         <span className="pre-line">{message}</span>
      </div>
   );
}
