class Messages {
   [key: string]: string;
   actions = 'Acciones';
   active = 'Activo';
   addToCart = 'Agregar al carrito';
   admin = 'Administrador';
   cancel = 'Cancelar';
   cart = 'Carrito';
   cartStock = '¡Todos los productos del carrito se encuentran en stock!';
   category = 'Categoría';
   confirm = 'Confirmar';
   confirmCart = 'Confirmar envío de carrito';
   confirmCartHelp = 'El carrito se vaciará luego de confirmar';
   confirmDeletion = 'Confirmar eliminación';
   contact = 'Contacto';
   delete = 'Eliminar';
   description = 'Descripción';
   duplicate = 'Duplicar';
   edit = 'Editar';
   editProduct = 'Editar producto';
   editSetup = 'Editar armado';
   emptyCart = 'El carrito está vacío';
   hide = 'Ocultar';
   home = 'Home';
   inactive = 'Inactivo';
   isPC = 'Es PC';
   login = 'Ingresar';
   logout = 'Salir';
   name = 'Nombre';
   new = 'Nuevo';
   newProduct = 'Nuevo producto';
   newSetup = 'Nuevo armado';
   nick = 'Usuario';
   noProducts = 'No hay productos';
   noSetups = 'No hay armados';
   notFound = 'No encontrado';
   password = 'Contraseña';
   pcProducts = 'Productos';
   photo = 'Foto';
   price = 'Precio';
   priceHigherToLower = 'Precio - mayor a menor';
   priceLowerToHigher = 'Precio - menor a mayor';
   priceRange = 'Rango de precio';
   product = 'Producto';
   productAddedToCart = 'Producto agregado al carrito';
   productCategories = 'Categorías de productos';
   productDeleted = 'Producto eliminado';
   productDuplicated = 'Producto duplicado';
   productRemovedFromCart = 'Producto eliminado del carrito';
   products = 'Productos';
   productsInCart = 'Producto(s) en el carrito ({0})';
   productSaved = 'Producto guardado';
   quantity = 'Cantidad';
   save = 'Guardar';
   search = 'Buscar';
   searchCriteriaValidation = 'Se requieren al menos 3 caracteres para buscar';
   sessionExpired = 'La sesión ha expirado';
   setupDeleted = 'Armado eliminado';
   setups = 'Armados';
   setupSaved = 'Armado guardado';
   show = 'Mostrar';
   systemDown = 'Ups! Parece que el sistema está caído en este momento';
   taxesIncluded = 'IVA inc.';
   title = 'Título';
   total = 'Total';
   usd = 'USD';
   validationsNumber = 'Debe ser un número';
   validationsPositive = 'Debe ser mayor a 0';
   validationsRequired = 'Campo requerido';
   view = 'Ver';
   viewCart = 'Ver carrito';
   viewMore = 'Ver más';
   viewLess = 'Ver menos';
   visibility = 'Visibilidad';
}

const msgs = new Messages();
export default msgs;
