import LoginRequest from 'src/models/request/loginRequest';
import api from './base/api';

const resource = 'session';

class SessionApi {
   get(): Promise<boolean> {
      return api.get(resource);
   }

   login(request: LoginRequest): Promise<string> {
      return api.post('login', request);
   }
}

export default new SessionApi();
