export default class Product {
   id = 0;
   name = '';
   categoryId: number;
   description = '';
   price: number = '' as any;
   isPC = false;
   isActive = true;
   photo: string;
   pcProducts: Product[] = [];
}
