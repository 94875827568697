import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import routes from 'src/routing/routes';
import msgs from 'src/util/msgs';
import session from 'src/util/session';
import Icon, { icons } from '../ui/Icon';

export default function AdminPanel() {
   return (
      <Card className="mt-3">
         <Card.Header className="bg-primary">
            <strong className="text-white text-uppercase">{msgs.admin}</strong>
         </Card.Header>

         <Card.Body className="py-3">
            <p className="mb-2">
               <Link to={routes.newProduct} className="text-body">
                  <Icon className="text-primary" icon={icons.faPlus} fixedWidth /> {msgs.newProduct}
               </Link>
            </p>

            <p>
               <Link to={routes.setups} className="text-body">
                  <Icon className="text-primary" icon={icons.faThLarge} fixedWidth /> {msgs.setups}
               </Link>
            </p>

            <span className="cursor-pointer" onClick={session.logout}>
               <Icon className="text-primary" icon={icons.faSignOutAlt} fixedWidth /> {msgs.logout}
            </span>
         </Card.Body>
      </Card>
   );
}
