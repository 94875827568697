import { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import useCategoriesContext from 'src/hooks/contexts/useCategoriesContext';
import routes from 'src/routing/routes';

export default function useOpenParentOnLoad(id: number, onOpen: () => void) {
   const location = useLocation(),
      [, allCategories] = useCategoriesContext();

   useEffect(() => {
      const match = matchPath<{ name: string }>(location.pathname, {
         path: routes.category,
         exact: true,
      });

      if (match) {
         const category = allCategories.find(x => x.normalizedName === match.params.name);

         if (category.id === id) {
            onOpen();
         }
      }
   }, []);
}
