import * as Yup from 'yup';
import msgs from './msgs';
import ui from './ui';

const searchCriteriaMinLength = 3;

export function requiredValidation() {
   return Yup.string().required(msgs.validationsRequired);
}

export function numberRequiredValidation() {
   return Yup.number().typeError(msgs.validationsNumber).required(msgs.validationsRequired);
}

export function arrayRequiredValidation() {
   return Yup.array().min(1, msgs.validationsRequired);
}

export function isValidSearchCriteria(criteria: string) {
   const isValid = criteria?.length >= searchCriteriaMinLength;

   if (criteria && !isValid) {
      ui.error(msgs.searchCriteriaValidation);
   }

   return isValid;
}
