import { ReactText } from 'react';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { icons } from 'src/components/ui/Icon';
import ToastContent from 'src/components/ui/ToastContent';
import events from './events';
import msgs from './msgs';

const defaultFadeDuration = 250,
   fadeInterval = 25,
   fadeInTimers: { element: HTMLElement; timer: NodeJS.Timeout }[] = [];

class UI {
   loading(show = true) {
      events.loading.emit(show);
   }

   hideLoading() {
      this.loading(false);
   }

   /**
    * Shows a success toast.
    */
   success(message: string) {
      return new Toast(toast.success(ToastContent({ icon: icons.faCheck, message: message })));
   }

   /**
    * Shows an error toast.
    */
   error(message: string) {
      return new Toast(toast.error(ToastContent({ icon: icons.faTimes, message: message })));
   }

   /**
    * Shows an info toast.
    */
   info(message: string) {
      return new Toast(toast.info(ToastContent({ icon: icons.faInfo, message: message })));
   }

   /**
    * Shows a warning toast.
    */
   warning(message: string) {
      return new Toast(toast.warning(ToastContent({ icon: icons.faExclamationTriangle, message: message })));
   }

   async confirm(
      title = msgs.confirmDeletion,
      message: string = undefined,
      confirmText = msgs.delete,
      cancelText = msgs.cancel,
   ) {
      return (
         (
            await Swal.fire({
               title: title,
               text: message,
               icon: 'warning',
               customClass: {
                  confirmButton: 'btn btn-success mr-2',
                  cancelButton: 'btn btn-danger',
                  icon: 'mt-0',
               },
               confirmButtonText: confirmText,
               showCancelButton: true,
               cancelButtonText: cancelText,
               buttonsStyling: false,
            })
         ).value === true
      );
   }

   fadeIn(element: HTMLElement, display = 'inline-block', duration = defaultFadeDuration) {
      if (!element) return;

      element.style.opacity = '0';
      element.style.filter = 'alpha(opacity=0)';
      element.style.display = display;
      element.style.visibility = 'visible';

      if (duration) {
         let opacity = 0;

         const timer = setInterval(() => {
            opacity += fadeInterval / duration;

            if (opacity >= 1) {
               clearInterval(timer);
               opacity = 1;
            }

            element.style.opacity = opacity.toString();
            element.style.filter = `alpha(opacity=${opacity * 100})`;
         }, fadeInterval);

         fadeInTimers.push({ element, timer });
      } else {
         element.style.opacity = '1';
         element.style.filter = 'alpha(opacity=1)';
      }
   }

   fadeOut(element: HTMLElement, duration = defaultFadeDuration) {
      if (!element) return;

      if (duration) {
         let opacity = 1;

         const fadeInTimer = fadeInTimers.find(x => x.element === element);

         if (fadeInTimer) {
            clearInterval(fadeInTimer.timer);
            fadeInTimers.splice(fadeInTimers.indexOf(fadeInTimer, 1));
         }

         const timer = setInterval(() => {
            opacity -= fadeInterval / duration;

            if (opacity <= 0) {
               clearInterval(timer);
               opacity = 0;
               element.style.display = 'none';
               element.style.visibility = 'hidden';
            }

            element.style.opacity = opacity.toString();
            element.style.filter = `alpha(opacity=${opacity * 100})`;
         }, fadeInterval);
      } else {
         element.style.opacity = '0';
         element.style.filter = 'alpha(opacity=0)';
         element.style.display = 'none';
         element.style.visibility = 'hidden';
      }
   }

   focus(ref: React.MutableRefObject<HTMLElement>) {
      ref.current?.focus();
   }

   removeFocus() {
      (document.activeElement as any).blur();
   }

   scrollTop() {
      window.scrollTo(0, 0);
   }
}

class Toast {
   constructor(private id: ReactText) { }

   dismiss() {
      toast.dismiss(this.id);
   }
}

export default new UI();
