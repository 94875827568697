export interface AppRoute {
   path: string | string[];
   title: string;
   component: () => JSX.Element;
   props?: { [key: string]: any };
}

const routes = {
   index: '/',
   notFound: '/no-encontrado',
   login: '/login',
   category: '/categorias/:name',
   search: '/busqueda/:criteria',
   cart: '/carrito',
   productDetail: '/productos/:id/:name?',
   newProduct: '/productos/nuevo',
   editProduct: '/productos/:id/editar',
   setups: '/armados',
   editSetup: '/armados/:id',
};

export function formatRoute(path: string, params: { [key: string]: any }) {
   Object.keys(params)
      .sort((a, b) => b.length - a.length)
      .forEach(key => (path = path.replace(`:${key}`, params[key])));

   return path.replaceAll('?', '');
}

export default routes;
