import { useParams } from 'react-router-dom';
import useCategoriesContext from './contexts/useCategoriesContext';

export default function useCategoryId() {
   const { name } = useParams<any>(),
      [, allCategories] = useCategoriesContext(),
      id = allCategories.find(x => x.normalizedName === name)?.id;

   return id;
}
