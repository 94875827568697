import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Icon, { icons } from 'src/components/ui/Icon';
import Category from 'src/models/category';
import { getCategoryRoute } from 'src/util/util';
import SubCategoryItem from './SubCategoryItem';

export default function CategoryItem({ item }: { item: Category }) {
   const [isOpen, setIsOpen] = useState(false),
      isLink = !item.subCategories.length,
      className = 'd-flex justify-content-between align-items-center',
      nameElement = <span className="text-uppercase">{item.name}</span>,
      toggleIcon = isOpen ? icons.faMinus : icons.faPlus;

   return (
      <div className={`category-item${isOpen ? ' open' : ''}`}>
         {isLink && (
            <NavLink className={className} to={getCategoryRoute(item)}>
               {nameElement}
               <Icon icon={icons.faCaretRight} />
            </NavLink>
         )}

         {!isLink && (
            <>
               <div className={`${className} cursor-pointer`} onClick={() => setIsOpen(!isOpen)}>
                  {nameElement}
                  <Icon icon={toggleIcon} />
               </div>

               {item.subCategories.map(x => (
                  <SubCategoryItem key={x.id} item={x} openParent={() => setIsOpen(true)} />
               ))}
            </>
         )}
      </div>
   );
}
