import { useEffect, useMemo, useState } from 'react';
import { Button, Col, FormControl, InputGroup, Row } from 'react-bootstrap';
import Icon, { icons } from 'src/components/ui/Icon';
import Category from 'src/models/category';
import Product from 'src/models/product';
import productApi from 'src/services/productApi';
import msgs from 'src/util/msgs';
import ui from 'src/util/ui';
import ProductGrid from '../../Products/ProductGrid';

interface Props {
   categories: Category[];
   selectedProducts: Product[];
   setSelectedProducts: (value: Product[]) => void;
}

export default function ProductSelector({ categories, selectedProducts, setSelectedProducts }: Props) {
   const [categoryId, setCategoryId] = useState<number>();

   useEffect(() => {
      if (categories.length) {
         // La primera vez se setea el combo con el id de la primera categoría seleccionable
         const category = categories[0],
            categoryId = category.subCategories.length ? category.subCategories[0].id : category.id;

         setCategoryId(categoryId);
      }
   }, [categories]);

   const sortedSelectedProducts = useMemo(() => selectedProducts.sort((a, b) => b.price - a.price), [selectedProducts]);

   const [products, setProducts] = useState<Product[]>([]),
      [productId, setProductId] = useState<number>();

   useEffect(() => {
      if (categoryId) {
         (async () => {
            ui.loading();

            const data = (await productApi.get(categoryId, true)).sort((a, b) => a.price - b.price);
            setProducts(data);

            ui.hideLoading();
         })();
      }
   }, [categoryId]);

   const filteredProducts = useMemo(
      () => products.filter(x => !selectedProducts.some(p => p.id === x.id)),
      [products, selectedProducts],
   );

   useEffect(() => {
      setProductId(filteredProducts.length ? filteredProducts[0].id : undefined);
   }, [filteredProducts]);

   function add() {
      const product = products.find(x => x.id === productId);
      setSelectedProducts(selectedProducts.concat([product]));
   }

   function remove(product: Product) {
      const products = [...selectedProducts];
      products.splice(products.indexOf(product), 1);
      setSelectedProducts(products);
   }

   return (
      <>
         <Row className="mb-2">
            <Col md="auto">
               <FormControl
                  as="select"
                  custom
                  className="mb-1 mb-md-0"
                  value={categoryId}
                  onChange={e => setCategoryId(+e.target.value)}
               >
                  {categories.map(x =>
                     x.subCategories.length > 0 ? (
                        <optgroup key={x.id} label={x.name}>
                           {x.subCategories.map(sc => (
                              <option key={sc.id} value={sc.id}>
                                 {sc.name}
                              </option>
                           ))}
                        </optgroup>
                     ) : (
                        <option key={x.id} value={x.id}>
                           {x.name}
                        </option>
                     ),
                  )}
               </FormControl>
            </Col>

            <Col>
               <InputGroup>
                  <InputGroup.Append>
                     <Button onClick={add} disabled={!filteredProducts.length}>
                        <Icon icon={icons.faPlus} />
                     </Button>
                  </InputGroup.Append>

                  <FormControl as="select" custom value={productId} onChange={e => setProductId(+e.target.value)}>
                     {filteredProducts.map(x => (
                        <option key={x.id} value={x.id}>
                           {msgs.usd} {x.price} - {x.name}
                        </option>
                     ))}
                  </FormControl>
               </InputGroup>
            </Col>
         </Row>

         <ProductGrid products={sortedSelectedProducts} onRemove={remove} showEdit={false} showToggle={false} />
      </>
   );
}
