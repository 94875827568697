import { forwardRef } from 'react';
import { FormCheck, FormControl } from 'react-bootstrap';
import { FormCheckType } from 'react-bootstrap/esm/FormCheck';
import FormikForm from 'src/types/formikForm';

interface Props {
   form: FormikForm<any>;
   type?: string;
   label?: string;
   name: string;
   text?: string;
   noValidate?: boolean;
   maxLength?: number;
   checked?: boolean;
   disabled?: boolean;
   id?: string;
   placeholder?: string;
   as?: React.ElementType<any>;
   custom?: boolean;
   rows?: string;
   children?: JSX.Element[];
}

function Input({ form, type = 'text', label, name, text, noValidate = false, ...props }: Props, ref: any) {
   const isBoolean = ['checkbox', 'radio', 'switch'].includes(type),
      validates = !noValidate,
      isValid = validates && form.touched[name] && !form.errors[name],
      isInvalid = validates && !!form.touched[name] && !!form.errors[name];

   return (
      <>
         {label && <h6>{label}</h6>}

         {isBoolean ? (
            <FormCheck
               ref={ref}
               type={type as FormCheckType}
               custom
               label={text}
               isValid={isValid}
               isInvalid={isInvalid}
               {...form.getFieldProps(name)}
               {...props}
            />
         ) : (
            <FormControl
               ref={ref}
               type={type}
               isValid={isValid}
               isInvalid={isInvalid}
               {...form.getFieldProps(name)}
               {...props}
            />
         )}
      </>
   );
}

export default forwardRef(Input);
