import { useMemo } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import useCategoriesContext from 'src/hooks/contexts/useCategoriesContext';
import Category from 'src/models/category';
import Product from 'src/models/product';
import { getCategoryRoute } from 'src/util/util';

class ProductPath {
   constructor(public category: Category) {}
   tree: Category[] = [];
}

export default function ProductBreadcrumb({ product }: { product: Product }) {
   const [categories, allCategories] = useCategoriesContext();

   const breadcrumbPath = useMemo(() => {
      if (product && categories.length) {
         const category = allCategories.find(x => x.id === product.categoryId),
            path = new ProductPath(category);

         if (category.parentId) {
            const subCategory = allCategories.find(x => x.id === category.parentId);
            path.tree.unshift(subCategory);

            if (subCategory.parentId) {
               const subSubCategory = allCategories.find(x => x.id === subCategory.parentId);
               path.tree.unshift(subSubCategory);
            }
         }

         return path;
      }

      return null;
   }, [product, categories]);

   const categoryRoute = getCategoryRoute(breadcrumbPath?.category);

   return (
      breadcrumbPath && (
         <Breadcrumb>
            {breadcrumbPath.tree.map(x => (
               <Breadcrumb.Item active key={x.id} className="text-uppercase">
                  {x.name}
               </Breadcrumb.Item>
            ))}

            <li className="breadcrumb-item">
               <Link to={categoryRoute} className="text-uppercase">
                  {breadcrumbPath.category.name}
               </Link>
            </li>
         </Breadcrumb>
      )
   );
}
