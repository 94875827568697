import Category from 'src/models/category';
import routes, { formatRoute } from 'src/routing/routes';
import FormikForm from 'src/types/formikForm';

export function formatString(formattedString: string, ...params: any[]) {
   if (formattedString) {
      for (let i = 0; i < params.length; i++) {
         formattedString = formattedString.replaceAll(`{${i}}`, params[i]);
      }
   }

   return formattedString || '';
}

export function getMatchingPropsValues(propsSource: { [key: string]: any }, valuesSource: { [key: string]: any }) {
   return Object.keys(propsSource).reduce((obj: { [key: string]: any }, key) => {
      obj[key] = valuesSource[key];
      return obj;
   }, {});
}

export function setFormValues(form: FormikForm<any>, values: { [key: string]: any }) {
   form.setValues(getMatchingPropsValues(form.values, values));
}

export function getCategoryRoute(category: Category) {
   return formatRoute(routes.category, { name: category?.normalizedName });
}
