import Setup from 'src/models/setup';
import api from './base/api';

const resource = 'setups';

class SetupApi {
   get(isLoggedIn: boolean): Promise<Setup[]> {
      return api.get(`${resource}${isLoggedIn ? '/uncached' : ''}`);
   }

   find(id: number): Promise<Setup> {
      return api.get(`${resource}/${id}`);
   }

   create(data: Setup): Promise<number> {
      return api.post(resource, data);
   }

   update(id: number, data: Setup): Promise<any> {
      return api.put(`${resource}/${id}`, data);
   }

   remove(id: number): Promise<any> {
      return api.delete(`${resource}/${id}`);
   }
}

export default new SetupApi();
