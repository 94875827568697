import EventEmitter from 'EventEmitter';
import CartProduct from 'src/models/cartProduct';
import Product from 'src/models/product';

const emitter = new EventEmitter();

export class AppEvent<T> {
   constructor(private name: string) { }

   subscribe(callback: Function) {
      return emitter.on(this.name, callback);
   }

   unsubscribe(callback: Function) {
      emitter.off(this.name, callback);
   }

   emit(value?: T) {
      emitter.emit(this.name, value);
   }
}

const events = {
   loading: new AppEvent<boolean>('loading'),
   login: new AppEvent<any>('login'),
   logout: new AppEvent<any>('logout'),
   search: new AppEvent<string>('search'),
   cartProductAdd: new AppEvent<Product>('cartProductAdd'),
   cartProductRemove: new AppEvent<CartProduct>('cartProductRemove'),
   cartClear: new AppEvent<any>('cartClear'),
};

export default events;
