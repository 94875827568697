import events from './events';

function deleteToken() {
   delete localStorage.token;
}

class Session {
   getToken() {
      return localStorage.getItem('token') || '';
   }

   setSessionData(token: string) {
      localStorage.token = token;
      events.login.emit();
   }

   logout() {
      deleteToken();
      events.logout.emit();
   }

   deleteToken() {
      deleteToken();
   }
}

export default new Session();
