import { useEffect, useState } from 'react';
import {
   useOnCartClear,
   useOnCartProductAdd,
   useOnCartProductRemove,
} from 'src/hooks/events/useCartProductEvents';
import CartProduct from 'src/models/cartProduct';
import StorageCart from 'src/models/storage/cart';
import msgs from 'src/util/msgs';
import ui from 'src/util/ui';

const expirationHours = 24;

export default function useCart() {
   const [cart, setCart] = useState<CartProduct[]>([]);

   useEffect(() => {
      if (localStorage.cart) {
         const savedCart: StorageCart = JSON.parse(localStorage.cart);

         if (savedCart && savedCart.products instanceof Array) {
            const dateDiff = +new Date() - savedCart.date,
               diffHours = dateDiff / 1000 / 60 / 60,
               isExpired = diffHours > expirationHours;

            if (isExpired) {
               clearCart();
            } else {
               setCart(savedCart.products);
            }
         }
      }
   }, []);

   useOnCartProductAdd(
      product => {
         let cartProduct = cart.find(x => x.product.id === product.id);

         if (cartProduct) {
            cartProduct.quantity++;
            setCart([...cart]);
         } else {
            cartProduct = new CartProduct(product);
            setCart(cart.concat([cartProduct]));
         }

         ui.success(msgs.productAddedToCart);
      },
      [cart],
   );

   useOnCartProductRemove(
      cartProduct => {
         const value = [...cart];
         value.splice(value.indexOf(cartProduct), 1);
         setCart(value);

         ui.success(msgs.productRemovedFromCart);
      },
      [cart],
   );

   useOnCartClear(clearCart, [cart]);

   function clearCart() {
      setCart([]);
   }

   useEffect(() => {
      if (cart.length) {
         const storageCart = new StorageCart(cart);
         localStorage.cart = JSON.stringify(storageCart);
      } else {
         delete localStorage.cart;
      }
   }, [cart]);

   return cart;
}
