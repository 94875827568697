import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Icon, { icons } from 'src/components/ui/Icon';
import Tooltip from 'src/components/ui/Tooltip';
import useSessionContext from 'src/hooks/contexts/useSessionContext';
import Product from 'src/models/product';
import msgs from 'src/util/msgs';
import { getDetailRoute, getEditRoute, getProductPhotoPath, onImageError, toggleVisiblity } from 'src/util/productUtil';

interface Props {
   product: Product;
   onRemove: () => void;
   showEdit?: boolean;
   showToggle?: boolean;
}

export default function ProductGridItem({ product, onRemove, showEdit = true, showToggle = true }: Props) {
   const isLoggedIn = useSessionContext(),
      detailRoute = getDetailRoute(product),
      editRoute = getEditRoute(product.id);

   return (
      <Card className="product-grid-item">
         <div className="img-container">
            <Link to={detailRoute}>
               <Card.Img variant="top" src={getProductPhotoPath(product.id)} onError={onImageError} />
            </Link>
         </div>

         <Card.Body>
            <Card.Title as="h6" className="font-weight-normal text-uppercase">
               {product.name}
            </Card.Title>

            <Card.Subtitle>
               {msgs.usd} {product.price}
            </Card.Subtitle>
         </Card.Body>

         {isLoggedIn && (
            <Card.Footer>
               {showEdit && (
                  <Tooltip title={msgs.edit}>
                     <Link to={editRoute} className="btn btn-outline-primary btn-sm mr-1">
                        <Icon icon={icons.faEdit} />
                     </Link>
                  </Tooltip>
               )}

               <Tooltip title={msgs.delete}>
                  <Button variant="outline-primary" size="sm" onClick={onRemove}>
                     <Icon icon={icons.faTrash} />
                  </Button>
               </Tooltip>

               {showToggle && (
                  <Tooltip title={product.isActive ? msgs.hide : msgs.show}>
                     <Button
                        variant="outline-primary"
                        size="sm"
                        className="ml-1"
                        onClick={() => toggleVisiblity(product)}
                     >
                        <Icon icon={product.isActive ? icons.faEyeSlash : icons.faEye} />
                     </Button>
                  </Tooltip>
               )}
            </Card.Footer>
         )}
      </Card>
   );
}
