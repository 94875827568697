import { useEffect } from 'react';
import globals from 'src/util/globals';

export default function useCancelPendingRequests() {
   useEffect(
      () => () => {
         globals.pendingRequestTokens.forEach(x => x.cancel('Cancelled by navigation'));
         globals.pendingRequestTokens = [];
      },
      [],
   );
}
