import { Badge, Dropdown, Image } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import useCartTotal from 'src/hooks/cart/useCartTotal';
import useCartContext from 'src/hooks/contexts/useCartContext';
import routes from 'src/routing/routes';
import msgs from 'src/util/msgs';
import { getDetailRoute, getProductPhotoPath, onImageError } from 'src/util/productUtil';
import { formatString } from 'src/util/util';
import Icon, { icons } from '../../ui/Icon';

export default function CartSummary() {
   const cart = useCartContext(),
      total = useCartTotal(cart);

   return (
      <Dropdown>
         <Dropdown.Toggle size="sm" className="cart-dropdown-toggle">
            <Icon className="mr-2" icon={icons.faShoppingCart} />
            {msgs.usd} {total}
         </Dropdown.Toggle>

         <Dropdown.Menu alignRight className={cart.length ? 'pb-0' : ''}>
            <Dropdown.Header className="text-center">
               <strong>{cart.length ? formatString(msgs.productsInCart, cart.length) : msgs.emptyCart}</strong>
            </Dropdown.Header>

            {cart.length > 0 && (
               <>
                  <div className="cart-dropdown-items">
                     {cart.map((x, i) => (
                        <>
                           <Dropdown.Divider></Dropdown.Divider>

                           <LinkContainer to={getDetailRoute(x.product)} activeClassName={null}>
                              <Dropdown.Item>
                                 <table>
                                    <tr>
                                       <td className="position-relative">
                                          <Image
                                             thumbnail
                                             className="cart-dropdown-img"
                                             src={getProductPhotoPath(x.product.id)}
                                             onError={onImageError}
                                          />
                                          <Badge variant="primary" className="cart-dropdown-item-badge">
                                             {x.quantity}
                                          </Badge>
                                       </td>
                                       <td className="pl-2">{x.product.name}</td>
                                    </tr>
                                 </table>
                              </Dropdown.Item>
                           </LinkContainer>
                        </>
                     ))}
                  </div>

                  <LinkContainer to={routes.cart}>
                     <Dropdown.Item className="mt-2 bg-primary text-white text-center">{msgs.viewCart}</Dropdown.Item>
                  </LinkContainer>
               </>
            )}
         </Dropdown.Menu>
      </Dropdown>
   );
}
