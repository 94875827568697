import { Col, FormCheck, Row } from 'react-bootstrap';
import Nouislider from 'react-nouislider';
import Range from 'src/models/range';
import msgs from 'src/util/msgs';

interface Props {
   sortLowerPriceFirst: boolean;
   setSortLowerPriceFirst: (value: boolean) => void;
   range: Range;
   filterRange: Range;
   setFilterRange: (value: Range) => void;
}

export default function ProductFilter({
   sortLowerPriceFirst,
   setSortLowerPriceFirst,
   range,
   filterRange,
   setFilterRange,
}: Props) {
   function onFilterRangeChange(values: string[]) {
      const [min, max] = values,
         newRange = new Range(+min, +max);

      setFilterRange(newRange);
   }

   return (
      <Row className="mb-3">
         <Col xs="auto">
            <FormCheck
               id="priceSortingLower"
               className="mb-2"
               type="radio"
               custom
               label={msgs.priceLowerToHigher}
               checked={sortLowerPriceFirst}
               onChange={() => setSortLowerPriceFirst(true)}
            ></FormCheck>

            <FormCheck
               id="priceSortingHigher"
               type="radio"
               custom
               label={msgs.priceHigherToLower}
               checked={!sortLowerPriceFirst}
               onChange={() => setSortLowerPriceFirst(false)}
            ></FormCheck>
         </Col>

         <Col>
            <p className="mb-2 font-weight-bold">{msgs.priceRange}</p>

            <Nouislider
               className="pr-3"
               tooltips
               range={range}
               start={[filterRange.min, filterRange.max]}
               onChange={(values: string[]) => onFilterRangeChange(values)}
            />
         </Col>
      </Row>
   );
}
