import { useEffect, useState } from 'react';
import sessionApi from 'src/services/sessionApi';
import session from 'src/util/session';

export default function useIsLoggedIn(): [boolean, React.Dispatch<React.SetStateAction<boolean>>] {
   const [isLoggedIn, setIsLoggedIn] = useState<boolean>();

   useEffect(() => {
      if (session.getToken()) {
         (async () => {
            try {
               const result = await sessionApi.get();
               setIsLoggedIn(result || false);
            } catch {
               setIsLoggedIn(false);
               session.deleteToken();
            }
         })();
      } else {
         setIsLoggedIn(false);
         session.deleteToken();
      }
   }, []);

   return [isLoggedIn, setIsLoggedIn];
}
