import { Redirect, Route, Switch } from 'react-router-dom';
import Cart from 'src/components/pages/Cart';
import Home from 'src/components/pages/Home';
import Login from 'src/components/pages/Login';
import ProductForm from 'src/components/pages/private/ProductForm';
import Setups from 'src/components/pages/private/Setups';
import ProductDetail from 'src/components/pages/ProductDetail';
import Products from 'src/components/pages/Products';
import useSessionContext from 'src/hooks/contexts/useSessionContext';
import msgs from 'src/util/msgs';
import DefaultRoutes from './DefaultRoutes';
import MapRoutes from './MapRoutes';
import routes, { AppRoute } from './routes';

export const pageRoutes: AppRoute[] = [
   {
      path: routes.index,
      title: msgs.home,
      component: Home,
   },
   {
      path: routes.login,
      title: msgs.login,
      component: Login,
   },
   {
      path: [routes.category, routes.search],
      title: msgs.products,
      component: Products,
   },
   {
      path: routes.productDetail,
      title: msgs.product,
      component: ProductDetail,
   },
   {
      path: routes.cart,
      title: msgs.cart,
      component: Cart,
   },
];

export const privateRoutes: AppRoute[] = [
   {
      path: [routes.newProduct, routes.editProduct],
      title: msgs.products,
      component: ProductForm,
   },
   {
      path: [routes.setups, routes.editSetup],
      title: msgs.setups,
      component: Setups,
   },
];

export default function PageRoutes() {
   const isLoggedIn = useSessionContext();

   return (
      <Switch>
         {isLoggedIn && (
            <Route path={routes.login} exact>
               <Redirect to={routes.index} />
            </Route>
         )}

         {isLoggedIn && MapRoutes(privateRoutes)}
         {MapRoutes(pageRoutes)}

         {!isLoggedIn &&
            privateRoutes.map(x => (
               <Route path={x.path} exact key={x.path.toString()}>
                  <Redirect to={routes.index} />
               </Route>
            ))}

         <DefaultRoutes />
      </Switch>
   );
}
