import { useFormik } from 'formik';
import { useEffect, useMemo } from 'react';
import { Button, Card, Col, FormControl, FormGroup, Row } from 'react-bootstrap';
import { Link, useHistory, useParams } from 'react-router-dom';
import Input from 'src/components/forms/Input';
import PhotoPicker from 'src/components/forms/PhotoPicker';
import ValidationSummary from 'src/components/forms/ValidationSummary';
import Icon, { icons } from 'src/components/ui/Icon';
import Tooltip from 'src/components/ui/Tooltip';
import useCategoriesContext from 'src/hooks/contexts/useCategoriesContext';
import useAutofocus from 'src/hooks/forms/useAutofocus';
import Category from 'src/models/category';
import Product from 'src/models/product';
import routes from 'src/routing/routes';
import productApi from 'src/services/productApi';
import { defaultProductPhotoPath } from 'src/util/constants';
import msgs from 'src/util/msgs';
import { deleteProduct, getEditRoute, getProductPhotoPath, onImageError } from 'src/util/productUtil';
import ui from 'src/util/ui';
import { getCategoryRoute, setFormValues } from 'src/util/util';
import { arrayRequiredValidation, numberRequiredValidation, requiredValidation } from 'src/util/validations';
import * as Yup from 'yup';
import ProductSelector from './ProductSelector';

export default function ProductForm() {
   const history = useHistory(),
      { id } = useParams<any>(),
      isCreating = !id,
      isEditing = !isCreating,
      [contextCategories, allCategories] = useCategoriesContext();

   const $name = useAutofocus(),
      form = useFormik({
         initialValues: new Product(),
         validationSchema: Yup.object({
            name: requiredValidation(),
            price: Yup.number().when('isPC', {
               is: false,
               then: numberRequiredValidation().positive(msgs.validationsPositive),
            }),
            description: requiredValidation(),
            pcProducts: Yup.array().when('isPC', {
               is: true,
               then: arrayRequiredValidation(),
            }),
         }),
         onSubmit: save,
      }),
      product = form.values;

   const isPC = useMemo(() => allCategories.find(x => x.id === +product.categoryId)?.isPC, [product.categoryId]);

   useEffect(() => {
      form.setFieldValue('isPC', isPC);
   }, [isPC, product.categoryId]);

   const calculatedPrice = useMemo(() => product.pcProducts.reduce((sum, x) => sum + x.price, 0), [product.pcProducts]);

   useEffect(() => {
      if (isPC) {
         form.setFieldValue('price', calculatedPrice);
      }
   }, [isPC, calculatedPrice]);

   const categories = useMemo(() => {
      const categories: Category[] = [];

      if (contextCategories.length) {
         const groups: Category[] = [],
            data: Category[] = JSON.parse(JSON.stringify(contextCategories));

         data.forEach(x => {
            let subGroups = x.subCategories.filter(sc => sc.subCategories.length);
            const hasSubGroups = subGroups.length > 0;

            if (hasSubGroups) {
               subGroups = subGroups.map(sg => {
                  sg.name = `${x.name} / ${sg.name}`;
                  return sg;
               });

               x.subCategories = x.subCategories.filter(sc => !sc.subCategories.length);

               if (x.subCategories.length > 0) {
                  groups.push(x);
               }

               groups.push(...subGroups);
            } else {
               groups.push(x);
            }
         });

         categories.push(...groups);
      }

      return categories;
   }, [contextCategories]);

   useEffect(() => {
      if (categories.length) {
         // La primera vez se setea el combo con el id de la primera categoría seleccionable
         const category = categories[0],
            categoryId = category.subCategories.length ? category.subCategories[0].id : category.id;

         form.setFieldValue('categoryId', categoryId);
      }
   }, [categories]);

   function reset() {
      // Se mantiene la categoría al guardar
      form.resetForm({ values: { ...form.initialValues, categoryId: product.categoryId } });
      $name.current.focus();
   }

   function goToNew() {
      history.push(routes.newProduct);
   }

   function goToCategory() {
      history.push(getCategoryRoute(allCategories.find(x => x.id === product.categoryId)));
   }

   async function getProduct() {
      ui.loading();

      try {
         const data = await productApi.find(id, true);
         ui.hideLoading();

         if (data) {
            setFormValues(form, {
               ...data,
               photo: getProductPhotoPath(data.id),
            });
         } else {
            goToNew();
         }
      } catch {
         goToNew();
      }
   }

   useEffect(() => {
      if (id) {
         getProduct();
      } else {
         reset();
      }
   }, [id]);

   async function remove() {
      if (await deleteProduct(product.id)) {
         goToCategory();
      }
   }

   async function save() {
      const request: Product = product,
         isNew = request.id === 0;

      ui.loading();

      if (isNew) {
         await productApi.create(request);
      } else {
         await productApi.update(request.id, request);
      }

      ui.hideLoading();
      ui.success(msgs.productSaved);

      isNew ? reset() : goToCategory();
   }

   async function duplicate() {
      ui.loading();

      const duplicateId = await productApi.duplicate(product.id);

      ui.hideLoading();

      if (duplicateId) {
         ui.success(msgs.productDuplicated);
         ui.scrollTop();

         history.push(getEditRoute(duplicateId));
      }
   }

   const categoryRoute = getCategoryRoute(allCategories.find(x => x.id === +product.categoryId));

   return (
      <Card>
         <Card.Body>
            <Card.Title>{isCreating ? msgs.newProduct : msgs.editProduct}</Card.Title>
            <hr />

            <form onSubmit={form.handleSubmit}>
               <Row>
                  <Col md>
                     <FormGroup>
                        <Input ref={$name} form={form} label={msgs.name} name="name" />
                     </FormGroup>
                  </Col>

                  <Col md>
                     <FormGroup>
                        <h6>
                           {msgs.category}{' '}
                           <Tooltip title={msgs.view}>
                              <Link to={categoryRoute} className="ml-1" tabIndex={-1}>
                                 <Icon icon={icons.faEye} />
                              </Link>
                           </Tooltip>
                        </h6>

                        <FormControl as="select" custom name="categoryId" {...form.getFieldProps('categoryId')}>
                           {categories.map(x =>
                              x.subCategories.length > 0 ? (
                                 <optgroup key={x.id} label={x.name}>
                                    {x.subCategories.map(sc => (
                                       <option key={sc.id} value={sc.id}>
                                          {sc.name}
                                       </option>
                                    ))}
                                 </optgroup>
                              ) : (
                                 <option key={x.id} value={x.id}>
                                    {x.name}
                                 </option>
                              ),
                           )}
                        </FormControl>
                     </FormGroup>
                  </Col>
               </Row>

               <Row>
                  <Col md>
                     <FormGroup>
                        <Input form={form} label={msgs.price} name="price" disabled={isPC} />
                     </FormGroup>
                  </Col>

                  <Col md>
                     <FormGroup>
                        <Input
                           form={form}
                           label={msgs.visibility}
                           text={product.isActive ? msgs.active : msgs.inactive}
                           checked={product.isActive}
                           name="isActive"
                           type="switch"
                           id="chkVisibility"
                           noValidate
                        />
                     </FormGroup>
                  </Col>
               </Row>

               <Row>
                  <Col md>
                     <FormGroup>
                        <Input as="textarea" form={form} label={msgs.description} name="description" rows="7" />
                     </FormGroup>
                  </Col>

                  <Col md>
                     <FormGroup>
                        <h6>{msgs.photo}</h6>

                        <PhotoPicker
                           src={product.photo}
                           imagePlaceholder={defaultProductPhotoPath}
                           onChange={data => form.setFieldValue('photo', data)}
                           onImageError={onImageError}
                        />
                     </FormGroup>
                  </Col>
               </Row>

               <FormGroup hidden={!isPC}>
                  <h6>{msgs.products}</h6>

                  <ProductSelector
                     categories={categories}
                     selectedProducts={product.pcProducts}
                     setSelectedProducts={x => form.setFieldValue('pcProducts', x)}
                  />
               </FormGroup>

               <div className="d-flex justify-content-between">
                  <div>
                     <Button type="submit">{msgs.save}</Button>

                     {isEditing && isPC && (
                        <Button className="ml-3" onClick={duplicate}>
                           {msgs.duplicate}
                        </Button>
                     )}

                     {isEditing && (
                        <Button className="ml-3" onClick={remove}>
                           {msgs.delete}
                        </Button>
                     )}
                  </div>

                  {isEditing && (
                     <Link to={categoryRoute} className="btn btn-primary">
                        {msgs.cancel}
                     </Link>
                  )}
               </div>

               <ValidationSummary form={form} className="mt-3" />
            </form>
         </Card.Body>
      </Card>
   );
}
