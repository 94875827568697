import { AxiosResponse } from 'axios';
import globals from 'src/util/globals';

export default function responseInterceptor(response: AxiosResponse) {
   try {
      if (response.config.cancelToken) {
         const i = globals.pendingRequestTokens.findIndex(x => x.token === response.config.cancelToken);

         if (i > -1) {
            globals.pendingRequestTokens.splice(i, 1);
         }
      }
      return response.data;
   } catch (error) {
      return Promise.reject(error);
   }
}
