import { Redirect, Route } from 'react-router-dom';
import NotFound from 'src/components/pages/NotFound';
import Page from 'src/components/pages/Page';
import msgs from 'src/util/msgs';
import routes from './routes';

export default function DefaultRoutes() {
   return (
      <>
         <Route path={routes.notFound} exact>
            <Page title={msgs.notFound} component={NotFound} />
         </Route>

         <Redirect from="*" to={routes.notFound} />
      </>
   );
}
