import { useEffect, useMemo, useState } from 'react';
import Category from 'src/models/category';
import categoryApi from 'src/services/categoryApi';

export default function useCategories() {
   const [categories, setCategories] = useState<Category[]>([]);

   const allCategories = useMemo(
      () =>
         categories.reduce(
            (all: Category[], category) =>
               [category, ...all].concat(
                  category.subCategories.reduce(
                     (allSc: Category[], subCategory) => [subCategory, ...allSc].concat(subCategory.subCategories),
                     [],
                  ),
               ),
            [],
         ),
      [categories],
   );

   useEffect(() => {
      (async () => {
         const data = await categoryApi.get();
         setCategories(data);
      })();
   }, []);

   return [categories, allCategories];
}
