import { useEffect, useState } from 'react';
import { Button, Col, Image, Row } from 'react-bootstrap';
import { Link, useHistory, useParams } from 'react-router-dom';
import useCategoriesContext from 'src/hooks/contexts/useCategoriesContext';
import useSessionContext from 'src/hooks/contexts/useSessionContext';
import Product from 'src/models/product';
import routes from 'src/routing/routes';
import productApi from 'src/services/productApi';
import events from 'src/util/events';
import msgs from 'src/util/msgs';
import { deleteProduct, getEditRoute, getProductPhotoPath, onImageError, toggleVisiblity } from 'src/util/productUtil';
import ui from 'src/util/ui';
import { getCategoryRoute } from 'src/util/util';
import Icon, { icons } from '../../ui/Icon';
import ProductBreadcrumb from './ProductBreadcrumb';

export default function ProductDetail() {
   const history = useHistory(),
      { id } = useParams<any>(),
      isLoggedIn = useSessionContext(),
      [, allCategories] = useCategoriesContext(),
      [product, setProduct] = useState<Product>(),
      [viewMore, setViewMore] = useState(false);

   function goToNotFound() {
      history.push(routes.notFound);
   }

   useEffect(() => {
      (async () => {
         ui.loading();

         try {
            const data = await productApi.find(id, isLoggedIn);
            ui.hideLoading();

            if (data) {
               setProduct(data);
            } else {
               goToNotFound();
            }
         } catch {
            goToNotFound();
         }
      })();
   }, [id]);

   function goToCategory() {
      history.push(getCategoryRoute(allCategories.find(x => x.id === product.categoryId)));
   }

   async function remove() {
      if (await deleteProduct(product.id)) {
         goToCategory();
      }
   }

   function toggleViewMore(value: boolean) {
      setViewMore(value);

      if (!value) {
         ui.scrollTop();
      }
   }

   function addToCart() {
      events.cartProductAdd.emit(product);
   }

   if (!product) {
      return <></>;
   }

   const editRoute = getEditRoute(product.id),
      descriptionLines = product.description.split('\n'),
      hasBigDescription = descriptionLines.length > 10,
      shortDescription = descriptionLines.slice(0, 9).join('\n'),
      description = hasBigDescription && !viewMore ? shortDescription : product.description;

   return (
      <>
         <ProductBreadcrumb product={product} />

         <Row>
            <Col md={5} className="mb-3">
               <Image thumbnail src={getProductPhotoPath(product.id)} onError={onImageError} />
            </Col>

            <Col>
               <h3 className="text-uppercase">{product.name}</h3>

               <div className="mb-4">
                  <h4 className="d-inline-block text-primary mb-0">
                     {msgs.usd} {product.price}
                  </h4>{' '}
                  <span className="text-muted">{msgs.taxesIncluded}</span>
               </div>

               <div className="mb-5">
                  <p className="pre-line text-break-word mb-0 position-relative">{description}</p>

                  {hasBigDescription && (
                     <div className={`text-center position-relative ${viewMore ? 'mt-2' : 'shadow-body'}`}>
                        <span className="text-primary cursor-pointer" onClick={() => toggleViewMore(!viewMore)}>
                           <Icon icon={viewMore ? icons.faAngleDoubleUp : icons.faAngleDoubleDown} className="mr-2" />
                           {viewMore ? msgs.viewLess : msgs.viewMore}
                        </span>
                     </div>
                  )}
               </div>

               <Button onClick={addToCart}>
                  <Icon icon={icons.faShoppingCart} className="mr-1" /> {msgs.addToCart}
               </Button>

               {isLoggedIn && (
                  <div className="mt-3">
                     <Link to={editRoute} className="btn btn-primary mr-2">
                        <Icon icon={icons.faEdit} className="mr-1" /> {msgs.edit}
                     </Link>

                     <Button onClick={remove} className="mr-2">
                        <Icon icon={icons.faTrash} className="mr-1" /> {msgs.delete}
                     </Button>

                     <Button onClick={() => toggleVisiblity(product)}>
                        <Icon icon={product.isActive ? icons.faEyeSlash : icons.faEye} className="mr-1" />{' '}
                        {product.isActive ? msgs.hide : msgs.show}
                     </Button>
                  </div>
               )}
            </Col>
         </Row>
      </>
   );
}
