import { Alert, Image, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import useCartTotal from 'src/hooks/cart/useCartTotal';
import useCartContext from 'src/hooks/contexts/useCartContext';
import CartProduct from 'src/models/cartProduct';
import events from 'src/util/events';
import msgs from 'src/util/msgs';
import { getDetailRoute, getProductPhotoPath, onImageError } from 'src/util/productUtil';
import ContactInfo from '../shared/contact-info';
import Icon, { icons } from '../ui/Icon';
import Tooltip from '../ui/Tooltip';

export default function Cart() {
   const cart = useCartContext(),
      total = useCartTotal(cart);

   function onRemove(cartProduct: CartProduct) {
      events.cartProductRemove.emit(cartProduct);
   }

   return (
      <>
         <Table striped hover>
            <thead>
               <tr className="bg-danger text-white">
                  <th {...{ width: 100 }}>{msgs.actions}</th>
                  <th {...{ width: 100 }}>{''}</th>
                  <th className="text-left">{msgs.product}</th>
                  <th>{msgs.price}</th>
                  <th>{msgs.quantity}</th>
                  <th>{msgs.total}</th>
               </tr>
            </thead>

            <tbody>
               {!cart.length && (
                  <tr>
                     <td colSpan={6}>{msgs.emptyCart}</td>
                  </tr>
               )}

               {cart.map(x => (
                  <tr key={x.product.id}>
                     <td className="align-middle">
                        <Tooltip title={msgs.delete}>
                           <span className="cursor-pointer" onClick={() => onRemove(x)}>
                              <Icon className="text-danger" icon={icons.faTrash} />
                           </span>
                        </Tooltip>
                     </td>

                     <td>
                        <Link className="d-none d-sm-inline" to={getDetailRoute(x.product)}>
                           <Image
                              thumbnail
                              className="w-100"
                              src={getProductPhotoPath(x.product.id)}
                              onError={onImageError}
                           />
                        </Link>
                     </td>
                     <td className="align-middle text-left">{x.product.name}</td>
                     <td className="align-middle">{x.product.price}</td>
                     <td className="align-middle">{x.quantity}</td>
                     <td className="align-middle">{x.product.price * x.quantity}</td>
                  </tr>
               ))}

               {cart.length > 0 && (
                  <tr className="bg-danger text-white font-weight-bold">
                     <td>{msgs.total}</td>
                     <td colSpan={4}></td>
                     <td>
                        {msgs.usd} {total} <span className="small">{msgs.taxesIncluded}</span>
                     </td>
                  </tr>
               )}
            </tbody>
         </Table>

         {cart.length > 0 && (
            <Alert variant="primary">
               <p>
                  <strong className="text-uppercase">{msgs.cartStock}</strong>
               </p>

               <ContactInfo />
            </Alert>
         )}
      </>
   );
}
