import Icon, { icons } from 'src/components/ui/Icon';
import msgs from 'src/util/msgs';

export default function NotFound() {
   return (
      <div className="text-center">
         <Icon icon={icons.faSadCry} size="10x" />

         <h3 className="mt-3">{msgs.notFound}</h3>
      </div>
   );
}
