import { useEffect } from 'react';
import useCategoryId from 'src/hooks/useCategoryId';
import useSessionContext from 'src/hooks/contexts/useSessionContext';
import Product from 'src/models/product';
import productApi from 'src/services/productApi';
import ui from 'src/util/ui';

export default function useGetCategoryProducts(setter: (value: Product[]) => void) {
   const id = useCategoryId(),
      isLoggedIn = useSessionContext();

   useEffect(() => {
      if (id) {
         (async () => {
            ui.loading();

            const data = await productApi.get(id, isLoggedIn);
            setter(data);

            ui.hideLoading();
         })();
      }
   }, [id]);

   return id;
}
