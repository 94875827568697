import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Icon, { icons } from 'src/components/ui/Icon';
import Category from 'src/models/category';
import { getCategoryRoute } from 'src/util/util';
import useOpenParentOnLoad from './hooks/useOpenParentOnLoad';

interface Props {
   item: Category;
   openParent: () => void;
}

export default function SubCategoryItem({ item, openParent }: Props) {
   const [isOpen, setIsOpen] = useState(false),
      isLink = !item.subCategories.length,
      nameElement = <span className="text-uppercase">{item.name}</span>,
      toggleIcon = isOpen ? icons.faMinus : icons.faPlus;

   useOpenParentOnLoad(item.id, onOpenParent);

   function onOpenParent() {
      setIsOpen(true);
      openParent();
   }

   return (
      <div className={`subcategory-item${isOpen ? ' open' : ''}`}>
         {isLink && (
            <NavLink to={getCategoryRoute(item)}>
               <Icon icon={icons.faCaretRight} className="mr-2" /> {nameElement}
            </NavLink>
         )}

         {!isLink && (
            <>
               <div className="cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
                  <Icon icon={toggleIcon} className="mr-1" /> {nameElement}
               </div>

               {item.subCategories.map(x => (
                  <SubCategoryItem key={x.id} item={x} openParent={onOpenParent} />
               ))}
            </>
         )}
      </div>
   );
}
