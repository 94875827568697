import { ToastContainer } from 'react-toastify';
import Layout from './components/layout';
import LoadingPage from './components/pages/LoadingPage';
import AppLoader from './components/ui/AppLoader';
import SessionContext from './contexts/sessionContext';
import useLoading from './hooks/events/useLoading';
import useOnLogin from './hooks/events/useOnLogin';
import useOnLogout from './hooks/events/useOnLogout';
import useCategories from './hooks/useCategories';
import useIsLoggedIn from './hooks/useIsLoggedIn';
import ui from './util/ui';

export default function App() {
   const isLoading = useLoading(),
      [isLoggedIn, setIsLoggedIn] = useIsLoggedIn(),
      sessionLoaded = isLoggedIn !== undefined,
      [categories, allCategories] = useCategories(),
      loadCompleted = sessionLoaded && categories.length > 0;

   useOnLogin(() => {
      setIsLoggedIn(true);

      ui.hideLoading();
   });

   useOnLogout(() => {
      setIsLoggedIn(null);
   });

   return (
      <>
         {!loadCompleted && <LoadingPage />}

         {loadCompleted && (
            <SessionContext.Provider value={isLoggedIn}>
               <Layout {...{ categories, allCategories }} />
            </SessionContext.Provider>
         )}

         <AppLoader show={isLoading} />
         <ToastContainer position="bottom-center" newestOnTop />
      </>
   );
}
