import { Container, Navbar, NavbarBrand } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import routes from 'src/routing/routes';
import globals from 'src/util/globals';
import CartSummary from './CartSummary';
import SearchBar from './SearchBar';

export default function TopBar() {
   const appName = globals.appName;

   return (
      <Navbar bg="dark" variant="dark" expand="md" className="px-md-0">
         <Container fluid="xl">
            <LinkContainer to={routes.index}>
               <NavbarBrand className="py-0 text-uppercase">
                  <img src="/img/logo.png" className="mr-1" alt="Logo" height="50" />{' '}
                  <span className="align-middle">{appName}</span>
               </NavbarBrand>
            </LinkContainer>

            <Navbar.Toggle />

            <Navbar.Collapse className="justify-content-end">
               <SearchBar className="mr-2" />
               <CartSummary />
            </Navbar.Collapse>
         </Container>
      </Navbar>
   );
}
