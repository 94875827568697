import CartProduct from 'src/models/cartProduct';
import Product from 'src/models/product';
import events from 'src/util/events';
import useOnEvent from './useOnEvent';

export function useOnCartProductAdd(callback: (product: Product) => void, deps?: React.DependencyList) {
   useOnEvent(events.cartProductAdd, callback, deps);
}

export function useOnCartProductRemove(callback: (cartProduct: CartProduct) => void, deps?: React.DependencyList) {
   useOnEvent(events.cartProductRemove, callback, deps);
}

export function useOnCartClear(callback: () => void, deps?: React.DependencyList) {
   useOnEvent(events.cartClear, callback, deps);
}