import { Col, Container, Row, Spinner } from 'react-bootstrap';

export default function LoadingPage() {
   const size = '10rem';

   return (
      <Container fluid>
         <Row className="bg-dark height-100 align-items-center">
            <Col>
               <div className="text-center">
                  <Spinner animation="border" variant="primary" style={{ width: size, height: size }} />
               </div>
            </Col>
         </Row>
      </Container>
   );
}
