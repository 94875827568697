import { useFormik } from 'formik';
import { Button, FormGroup } from 'react-bootstrap';
import Input from 'src/components/forms/Input';
import PhotoPicker from 'src/components/forms/PhotoPicker';
import ValidationSummary from 'src/components/forms/ValidationSummary';
import useAutofocus from 'src/hooks/forms/useAutofocus';
import Setup from 'src/models/setup';
import setupApi from 'src/services/setupApi';
import msgs from 'src/util/msgs';
import ui from 'src/util/ui';
import { requiredValidation } from 'src/util/validations';
import * as Yup from 'yup';

interface Props {
   source?: Setup;
   onSave: () => void;
}

export default function SetupForm({ source: setup, onSave }: Props) {
   setup = setup || new Setup();

   const $title = useAutofocus(),
      form = useFormik({
         initialValues: { ...setup },
         validationSchema: Yup.object({
            title: requiredValidation(),
            photo: requiredValidation(),
         }),
         onSubmit: save,
      });

   async function save() {
      const request: Setup = form.values;

      ui.loading();

      if (request.id === 0) {
         await setupApi.create(request);
      } else {
         await setupApi.update(request.id, request);
      }

      ui.success(msgs.setupSaved);
      ui.hideLoading();

      onSave();
   }

   return (
      <form onSubmit={form.handleSubmit}>
         <FormGroup>
            <Input ref={$title} form={form} label={msgs.title} name="title" maxLength={150} />
         </FormGroup>

         <FormGroup>
            <h6>{msgs.photo}</h6>

            <PhotoPicker src={form.values.photo} onChange={data => form.setFieldValue('photo', data)} />
         </FormGroup>

         <Button type="submit" block>
            {msgs.save}
         </Button>

         <ValidationSummary form={form} className="mt-3" />
      </form>
   );
}
