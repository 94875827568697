import { useMemo, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import Product from 'src/models/product';
import Range from 'src/models/range';
import routes from 'src/routing/routes';
import msgs from 'src/util/msgs';
import { deleteProduct } from 'src/util/productUtil';
import useGetCategoryProducts from './hooks/useGetCategoryProducts';
import useSearch from './hooks/useSearch';
import ProductFilter from './ProductFilter';
import ProductGrid from './ProductGrid';

export default function Products() {
   const [products, setProducts] = useState<Product[]>([]),
      categoryId = useGetCategoryProducts(setProducts),
      search = useSearch(setProducts),
      [sortLowerPriceFirst, setSortLowerPriceFirst] = useState(true),
      [filterRange, setFilterRange] = useState(new Range());

   const range: Range = useMemo(() => {
      const values = new Range();

      if (products.length) {
         const prices = products.map(x => x.price);
         values.min = Math.min(...prices);
         values.max = Math.max(...prices);

         if (values.min === values.max) {
            values.max *= 1.2;
         }
      }

      setFilterRange(values);

      return values;
   }, [products]);

   const filteredProducts = useMemo(() => {
      let filteredData = products;

      if (products.length) {
         filteredData = filteredData.filter(x => x.price >= filterRange.min && x.price <= filterRange.max);
      }

      return filteredData;
   }, [filterRange]);

   const sortedProducts = useMemo(
      () => filteredProducts.sort((a, b) => (sortLowerPriceFirst ? a.price - b.price : b.price - a.price)),
      [filteredProducts, sortLowerPriceFirst],
   );

   async function remove(product: Product) {
      if (await deleteProduct(product.id)) {
         const newValue = [...products];
         newValue.splice(newValue.indexOf(product), 1);
         setProducts(newValue);
      }
   }

   if (!(categoryId || search)) {
      return <Redirect to={routes.notFound} />;
   }

   return (
      <>
         {products.length > 1 && (
            <ProductFilter
               {...{ sortLowerPriceFirst, setSortLowerPriceFirst, range, filterRange, setFilterRange }}
            />
         )}

         <ProductGrid products={sortedProducts} onRemove={remove} />

         {!sortedProducts.length && <Alert variant="warning">{msgs.noProducts}</Alert>}
      </>
   );
}
