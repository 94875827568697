import { Route } from 'react-router-dom';
import Page from 'src/components/pages/Page';
import { AppRoute } from './routes';

export default function MapRoutes(routes: AppRoute[]) {
   return routes.map(x => {
      const key = x.path instanceof Array ? x.path[0] : x.path;

      return (
         <Route key={key} path={x.path} exact>
            <Page title={x.title} component={x.component} {...x.props} />
         </Route>
      );
   });
}
