import Category from 'src/models/category';
import api from './base/api';

const resource = 'categories';

class CategoryApi {
   get(): Promise<Category[]> {
      return api.get(resource, { cancelToken: null });
   }
}

export default new CategoryApi();
