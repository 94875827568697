import { Container } from 'react-bootstrap';
import { partnerLogoPath } from 'src/util/constants';
import { formatString } from 'src/util/util';

const imageNames = [
   'amd',
   'intel',
   'msi',
   'acer',
   'hp',
   'lenovo',
   'corsair',
   'kingston',
   'hyperx',
   'crucial',
   'patriot',
   'pny',
   'adata',
   'western-digital',
   'aorus',
   'evga',
   'asus',
   'gigabyte',
   'asrock',
   'zotac',
   'cooler-master',
   'thermaltake',
   'cougar',
   'logitech',
   'redragon',
   'seasonic',
   'viewsonic',
   'aoc',
];

export default function Partners() {
   return (
      <section className="bg-light py-3">
         <Container fluid className="d-flex justify-content-center flex-wrap">
            {imageNames.map((name, i) => (
               <img className="img-partner" src={formatString(partnerLogoPath, name)} alt={name} key={i} />
            ))}
         </Container>
      </section>
   );
}
