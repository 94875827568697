import { useFormik } from 'formik';
import { Button, Card, InputGroup } from 'react-bootstrap';
import Input from 'src/components/forms/Input';
import ValidationSummary from 'src/components/forms/ValidationSummary';
import Icon, { icons } from 'src/components/ui/Icon';
import useAutofocus from 'src/hooks/forms/useAutofocus';
import LoginRequest from 'src/models/request/loginRequest';
import sessionApi from 'src/services/sessionApi';
import msgs from 'src/util/msgs';
import session from 'src/util/session';
import ui from 'src/util/ui';
import { requiredValidation } from 'src/util/validations';
import * as Yup from 'yup';

export default function Login() {
   const $nick = useAutofocus(),
      form = useFormik({
         initialValues: {
            ...new LoginRequest(),
         },
         validationSchema: Yup.object({
            nick: requiredValidation(),
            password: requiredValidation(),
         }),
         onSubmit: login,
      });

   async function login() {
      ui.loading();
      const response = await sessionApi.login(form.values);
      session.setSessionData(response);
   }

   return (
      <>
         <Card className="login-form mx-auto">
            <Card.Body>
               <Card.Title>{msgs.login}</Card.Title>

               <form onSubmit={form.handleSubmit}>
                  <InputGroup className="form-group">
                     <InputGroup.Prepend>
                        <InputGroup.Text>
                           <Icon icon={icons.faUser} className="mr-1" />
                        </InputGroup.Text>
                     </InputGroup.Prepend>

                     <Input ref={$nick} form={form} name="nick" placeholder={msgs.nick} />
                  </InputGroup>

                  <InputGroup className="form-group">
                     <InputGroup.Prepend>
                        <InputGroup.Text>
                           <Icon icon={icons.faLock} className="mr-1" />
                        </InputGroup.Text>
                     </InputGroup.Prepend>

                     <Input type="password" form={form} name="password" placeholder={msgs.password} />
                  </InputGroup>

                  <Button type="submit" block>
                     <Icon icon={icons.faSignInAlt} fixedWidth /> {msgs.login}
                  </Button>

                  <ValidationSummary form={form} className="mt-3" />
               </form>
            </Card.Body>
         </Card>
      </>
   );
}
